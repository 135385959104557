.caisse-vente-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    right: 0;
    background-color: #262b4e;
    // background-color: #e89510;
    // background-color: #277850;
    // background-color: #6b0a56;
    // background-color: #067f97;
    // background-color: #c9484a;
    z-index: 9999;
    overflow: hidden;
}

.sq-caisse {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

/**
** Menu content **
**
*/
.logo {
    padding: 5px;
    height: 66px;
    background-color: #e6e6e6;
    color: #262b4e;
    // border-bottom-right-radius: 15px;
    position: relative;
}

.first-title {
    display: block;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.second-title {
    position: absolute;
    display: block;
    font-size: 13px;
    font-weight: bold;
    bottom: 5px;
    left: 58%;
}

.sq-menu {
    font-family: Arial, sans-serif;
    width: 10%;
    color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sq-menu-ul {
    list-style-type: none;
    padding: 0px 0px 5px 0px;
    margin: 0;
}

.sq-menu-li {
    border-bottom: 1px solid #2c3361;
    cursor: pointer;
}

.sq-menu-a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: #ffffff;
    text-decoration: none;
    font-size: 15px;
    // border-top-right-radius: 15px;
    // border-bottom-right-radius: 15px;
}

.sq-menu-a-vente{
    background: rgba(28, 69, 205, 0.891);
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
}
.sq-menu-a-vente:hover{
    background: rgba(24, 61, 180, 0.93)!important;
    color: #fff;
}

.sq-menu-a-credit{
    background: rgb(225, 49, 49);
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
}
.sq-menu-a-credit:hover{
    background: rgb(168, 50, 50)!important;
    color: #fff;
}

.sq-menu-a-solde{
    background: rgb(23, 161, 64);
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
}
.sq-menu-a-solde:hover{
    background: rgb(12, 123, 55)!important;
    color: #fff;
}

.sq-menu-a:hover {
    background-color: #e6e6e6;
    text-decoration: none;

}

.sq-menu-a>.fa {
    margin-right: 5px;
}

.sidebar-menus {
    height: 90%;
}

.sq-top-menu {
    height: 91%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.sq-bottom-menu {
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 3px;

}

/**
** Squelette content **
**
*/
.ml-3 {
    margin-left: 3px;
}

.mr-3 {
    margin-right: 3px;
}

.sq-content {
    width: 65%;
    position: relative;
    // background-color: aliceblue;
}


.top-btn-filter {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.content-categorie {
    clear: both;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    min-height: 44px;
}

.content-categorie-item {
    width: max-content;
    margin-right: 5px;
    margin-left: 5px;
    color: white;
    font-size: 13px;
    padding: 6px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.content-categorie-item.search-input {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    color: white;
    font-size: 13px;
    padding: 0px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

input#code_a_barre {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    // border-radius: 12px;
}

.content-categorie-item:hover {
    color: yellow;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
}

.content-products {
    height: 100%;
    background-color: white;
    display: flex;
}
.panel-products{
    overflow-y: auto;/* Vertical scrolling only */
    padding-bottom: 80px;
    height: 85%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    // background-color: rgb(189, 161, 245);
}
.wp-50{width: 50%!important;}
.wp-48{width: 47%!important;}
.wp-70{width: 70%!important;}
.wp-100{width: 100%!important;}

.hp-100{height: 100%!important;}

.btrr-50{border-top-right-radius: 18px;}
.bbrr-50{border-bottom-right-radius: 18px;}
.btlr-50{border-top-left-radius: 18px;}
.bblr-50{border-bottom-left-radius: 18px;}


.content-product-lines{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #eeecec;
    margin: 10px 5px;
    padding: 5px;
    height: 180px;
    border-radius: 5px;
    width: 98%;
}
.panel-info-paiement{
    padding-bottom: 80px;
    height: 85%;
    width: 30%;
    background-color: #eeecec;
}
.content-product-title{
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    width: 100%;
}
.content-product-image{
    position: relative;
   width: 20%; 
   height: 90%;
   cursor: pointer;
}
.content-product-image>i{
    font-size: 140px;
    position: absolute;
    color: #4de95f13;
    left: 10%;
}
.content-product-image>i:hover{
    color: #e9624d5c;
}

.content-product-cab{
    padding-top: 7px;
}
.content-product-image>img{
    width: 100%;
    height: 80%;
    border-radius: 15px;
}

.content-product-table{
    width: 80%; 
    padding-left: 5px;
 }

 .product-infos{
    display: flex;
    justify-content: space-around;
    padding-top: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #000;
 }

 .product-infos>.product-info{
    width: 13%;
    text-align: center;
 }

 .product-infos-values{
    display: flex;
    justify-content: space-around;
    height: 50px;
 }
 .product-infos-values>.product-info{
    width: 13%;
    height: 50px;
    font-weight: bold;
    text-align: center;
    padding-top: 15px;
    font-size: 14px;
 }
 .product-infos-values>.product-info>input{
    width: 100%;
    border: 0px;
    text-align: center;
 }

 .product-infos-total{
    display: flex;
    justify-content: space-around;
    margin-top: 3px;
    padding-top: 10px;
    border-bottom: 1px solid #000;
 }
 .product-infos-total>.product-info{
    width: 33%;
    text-align: center;
 }
 .product-infos-total-values{
    display: flex;
    justify-content: space-around;
    margin-top: 3px;
    padding-top: 5px;
 }

 .product-infos-total-values>.product-info{
    font-size: 14px;
    text-align: center;
    width: 33%;
    background-color: aquamarine;
 }

 /**
  **
  ** menu bottom of content
  **/
.content-bottom-menu {
    font-family: Arial, sans-serif;
    width: 100%;
    color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #262b4e;
    position: absolute;
    bottom: 0;
}

.content-sq-menu-ul {
    list-style-type: none;
    padding: 0px 5px;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
}

.content-sq-menu-li {
    border-bottom: 1px solid #2c3361;
    cursor: pointer;
}

.content-sq-menu-a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: #ffffff;
    text-decoration: none;
    font-size: 15px;
    // border-top-right-radius: 15px;
    // border-bottom-right-radius: 15px;
}

.content-sq-menu-a:hover {
    background-color: #e6e6e6;
    text-decoration: none;

}

.content-sq-menu-a>.fa {
    margin-right: 5px;
}





/**
** Squelette info  **
**
*/
.sq-info {
    width: 25%;
    position: relative;
}

.content-net-a-payer {
    height: 67px;
    background-color: purple;
}
.buttons-clear-prints{
    display: flex;
    justify-content: space-between;
    padding: 0px 6px;
    background-color: #e6e6e6;
}
.buttons-clear-prints>div{
    width: 25%;
}
.buttons-clear-prints>div>button{
    width: 95%;
}
/***************************************** tickets ***************************************/
.line-dashed {
    border: 1px dashed #262b4e;
    margin: 7px 15px;
}

.content-tickets {
    height: 43.3%;
    background-color: #e6e6e6;
    font-family: 'Courier New', monospace;
    position: relative;
    overflow-y: auto;/* Vertical scrolling only */
    padding-bottom: 40px;
}

/* Custom scrollbar styles */
/* Firefox */
.scroll-container::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

.scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Track color */
}

.scroll-container::-webkit-scrollbar-thumb {
    background-color: purple;
    /* Thumb color */
    border-radius: 0px;
    /* Rounded corners */
}

/* Chrome, Safari, Edge */
.scroll-container::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

.scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Track color */
}

.scroll-container::-webkit-scrollbar-thumb {
    background-color: purple;
    /* Thumb color */
    border-radius: 0px;
    /* Rounded corners */
}

.content-ticket-header {
    padding-top: 15px;
    padding-bottom: 7px;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
}

.panel-prods {
    margin-top: 10px;
    min-height: 120px;
}

.content-prods {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 2px 0px;
}
.content-prods:hover {
    background-color: #262b4e;
    color: white;
    cursor: pointer;
}

.content-prod-quantite {
    padding-left: 15px;
    width: 15%;
}

.content-prod-libelle {
    width: 60%;
}

.content-prod-total_ttc {
    padding-right: 15px;
    width: 25%;
    text-align: right;
}

.total-and-paiements {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.total-and-paiements-qrcode {
    width: 25%;
}

.total-and-paiements-libelle {
    width: 45%;
    text-align: right;
}

.total-and-paiements-value {
    width: 30%;
    text-align: right;
    padding-right: 15px;
}

/***************************************** fin tickets ***************************************/

// .content-buttons-actions,
// .content-buttons-print {
//     width: 100%;
//     position: relative;
// }
.content-buttons-actions{
    display: flex;
    padding: 5px;
    justify-content: space-around;
    background-color: #e6e6e6;
}
.content-buttons-actions>button{
    width: 48%;
    height: 50px;
    font-size: 15px;
}

.btn-action {
    width: 32.8%;
}

.btn-print {
    width: 49.5%;

}

.content-calc {
    height: 34%;
    width: 100%;
    position: relative;
}

.calc-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    right: 0;
    left: 0;
    // bottom: 0;
    height: 100%;
}

.calc-btn {
    color: white;
    width: 25%;
    height: 18%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    background-color: #2c3361;
    // border: 2px solid #ffffff00;
    align-content: center;
    cursor: pointer;
}

.calc-afficheurs {
    width: 100%;
}

.calc-afficheur-1 {
    width: 59%;
    height: 25px;
    font-weight: bold;
    font-size: 16px;
}

.calc-afficheur-2 {
    width: 40%;
    height: 25px;
    font-weight: bold;
    font-size: 16px;
}

.calc-btn:hover {
    background-color: #262b4e;
}

.calc-btn-green {
    background-color: green;
}

.calc-btn-yellow {
    background-color: rgb(197, 147, 30);
}

.calc-btn-hover {
    background-color: rgb(75, 197, 242);
}

.page404{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.page404>div:first-child, .page404>div:nth-child(2){
    font-size: 165px;
}
.page404>div:last-child{
    font-size: 100px;
}

.select_depot{
    margin-top: 5px;
    margin-right: 5px;
}

.bb-white{
    border-bottom: 2px solid #fff;
}
.distributeur_panel{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    font-size: 12px;
    padding: 5px;
    cursor: pointer;
}
.distributeur_panel:hover{
    background-color: #262b4e;
    color: #fff;
}
.distributeur_panel:hover{
    div.sousociete_name_value>input {
        color: #262b4e;
    }
}
.sousociete_name{
    width: 27%;
    text-align: center;
    color: blue;
}
.sousociete_name_value{
    width: 73%;
    text-align: center;
}
.c-black{
    color: black!important;
}
.info-bl{
    background: #9ef967;
    height: 170px;
    overflow: auto;
}
.info-bl-collapsed{
    background: #9ef967;
    height: 40px;
    overflow: auto;
}

.info-calculator{
    height: 170px;
    background-color: #760d90;
}

.menu-info-classeur{
    height: 30px;
    display: flex;
    justify-content: space-between;
    & div {
        width: 50%;
    }
}
.bg-purple{
    background-color: #760d90;
}
.bg-purple:hover{
    background-color: #5b0f6e;
}
.bg-greno{
    background-color: #9ef967;
}
.bg-greno:hover{ 
    background-color: #8dde5b;

}
.menu-classeur{
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    color: #c38320;
    cursor: pointer;
    & div {
        padding-top: 5px;
    }
}
.paiement-form{
    background: #e6cc71;
    height: 100%;
}
.input-passager{
    width: 100%;
    text-align: center;
    border: none;
}


/**
 ** Paiement
 **/
 .block-espece{
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 5px;
    flex-wrap: wrap;
 }
 .mt--20{
    margin-top: -20px;
 }
 .inputEspeceLabel{
    width: 100%;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    margin-top: 5px;
    margin-left: -15px;
 }
 .inputEspeceLabel>label{
     font-size: 15px;
    font-weight: bold;
    padding-left: 20px;
    color: #760d90;
    margin-bottom: 0px;
    cursor: pointer;
 }
 .inputEspece{
    width: 100%;
    padding-top: 2px;

 }
 .inputEspece>input.espece{
    width: 100%!important;
    border: none;
    height: 23px;
    font-size: 14px;
 }

 .block-cheque{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
 }
 .block-cheque>div{
    width: 45%;
    margin-bottom: 5px;
 }
 .block-cheque>div>input{
    border: none;
    height: 22px;
    font-size: 13px;
    width: 100%;
 }

 .block-tickets{
    display: flex;
    justify-content: space-around;
 }

 .block-tickets>div:first-child{
    width: 40%;
 }
 .block-tickets>div:nth-child(2){
    width: 24%;
 }
 .block-tickets>div:last-child{
    width: 34%;
 }
 .block-tickets>div>input,.block-tickets>div>select {
    width: 100%;
    height: 22px;
    font-size: 13px;
    border: none;
 }

 .panel_dists{
    padding: 0px 10px;
    margin: 0px 10px;
    border-bottom: 1px solid #262b4e;
 }
 .panel_soussocietes{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
 }
 .panel_soussocietes>div{
    width: 48%;
    background-color: #efefef;
    padding: 10px;
    text-align: center;
    border: 2px #fff;
    margin-bottom: 8px;
    cursor: pointer;
 }

 .panel_depots>div{
    width: 32%;
    background-color: #efefef;
    padding: 10px;
    text-align: center;
    border: 2px #fff;
    margin-bottom: 8px;
    cursor: pointer;
 }

 .old_selected_client{
    background-color: aqua!important;
    border: 2px solid #262b4e!important;
 }
 .dist_selected{
    background-color: aqua!important;
    border: 2px solid #262b4e!important;
 }

 .mt-15{
    margin-top: 15px;
 }

 .input_search_client{
    width: 100%;
    height: 30px;
    text-align: center;
 }

 .text-left{
    text-align: left;
 }
 .text-right{
    text-align: right;
 }
 .plafond-panel{
    background-color: #4de95f !important;
    padding: 3px; 
}

.mode_search_prod{
    position: absolute;
    right: 44%;
    height: 28px;
    top: 0px;
    width: 110px;
}
.mode_search_c_auto{
    background: #2085dd!important;
}
.mode_search_c_manuelle{
    background: #c38320 !important;
}

.ul-rapid-access{
    list-style: none;
    width: 100%;
    padding: 0px;
}

.li-entete{
    display: flex;
    justify-content: space-around;
    margin: 5px 5px 0px 5px;
    border-bottom: 1px solid #fff;    
}
.li-entete>.entete_type{width: 35%;text-align:center;font-size: 11px;}
.li-entete>.entete_code{width: 65%;text-align:right;font-size: 12px;}

input:focus{
    background-color: rgb(162, 208, 248);
}

.btn-add-panier{
    position: absolute;
    right: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
}

.last_searched_input{
    position: absolute;
    background: #3e96e3;
    padding: 2px 5px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    top: 5px;
    left: 0px;
    font-size: 15px;
    & span {
        color: rgb(255, 226, 163);
        font-weight: bold;
    }
}

.aucun_prod_info{
    font-size: 30px;
    text-align: center;
    color: #a0a0a0;
    margin-top: 150px;
}

.bg-input-success{
   // background: #7df8b7;
}
.bg-input-error{
    background: #f8787c;
}

.panel-resum-bl{
    display: flex;
    justify-content: space-around;
}

.panel-total-bl{
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    width: 40%;
    display: flex; 
    justify-content: space-between;
    flex-direction: column;
}
.panel-total-bl>div{
    padding: 4px 5px;
}
.panel-total-net-bl{
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    width: 50%;
    display: flex; 
    justify-content: space-between;
    flex-direction: column;

    //  background: #df8711;
    // padding: 0px 5px;
    // border-bottom-left-radius: 7px;
    // border-bottom-right-radius: 7px;
}
.panel-total-net-bl>div{
    padding: 4px 5px;
}

.panel-calcule-rest{
    color: #fff;
    font-size: 23px;
    font-weight: bold;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-around;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 17px;
    height: 160px;
}

.panel-calcule-rest>div{
    padding: 2px 5px;
}
.brd-b-1{
    border-bottom: 1px solid #df8711;
}

.play-bottom{
    transform: rotate(90deg);
}
label>i.fa-play{
    font-size: 8px;
    margin-top: -5px;
}

.panel-btn-action-cred{
    width: 100%;
    display: flex;
    justify-content: space-between;
    & button {
        width: 48%;
        margin-top: 12px;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
    }
}

.underline{
    text-decoration: underline;
}

.date_echeance_traite._720kb-datepicker-open{
    margin-top: -285px;
    margin-left: -89px;
}

.animate__fadeInRight{
    position: relative;
    z-index: 99;
}